import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const Wrapper = styled.section`
  @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'auto'};
  }
`

const HeroPageProfilo = props => (
  <Wrapper className="page-image-wrapper" height={props.height}>
    <Img className="page-image page-profilo" fluid={props.image.fluid} backgroundColor={'#eeeeee'} />
    <div className="page-breadcrumb">
        <Link to={`/`}>Home </Link> / {props.title}
    </div>
    <h1 className="page-title page-type">
      <span>Luca Belli</span>
    </h1>
    <div className="page-profilo-sub-title">
      Product Designer & Developer ✌ 
    </div>
    <div className="page-profilo-scopri">
    </div>
  </Wrapper>
)

export default HeroPageProfilo
