import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

const Wrapper = styled.section`
  @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'auto'};
  }
`

const Title = styled.h1`
  max-width: ${props => props.theme.sizes.maxWidthCentered};
`

const HeroPage = props => (
  <Wrapper className="page-image-wrapper" height={props.height}>
    <Img className="page-image small-hero-desktop" fluid={props.image.fluid} backgroundColor={'#eeeeee'} />
    <Title className="page-title page-type">
      {props.title}
    </Title>
  </Wrapper>
)

export default HeroPage
