import React from 'react'
import { Link } from 'gatsby'

const LBCondividi = props => (
  <div className="condividi">
    <span className="label-condividi">Condividi su: </span> 
    <Link to={"https://www.linkedin.com/shareArticle?url="+props.articleUrlEncoded}>Linkedin</Link>,{" "} 
    <Link to={"https://TwItTeR.CoM/intent/tweet?text="+props.articleTitleEncoded+"&url="+props.articleUrlEncoded}>Twitter</Link>
  </div>
)

export default LBCondividi
