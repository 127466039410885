import React, { useEffect } from 'react';
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import LBNavigation from '../components/LBNavigation'
import Container from '../components/Container'
import SEO from '../components/SEO'
import HeroPage from '../components/HeroPage'
import HeroPageProfilo from '../components/HeroPageProfilo'
import LBCondividi from '../components/LBCondividi';
import { useSiteMetadata } from '../hooks/use-site-metadata'

const PageTemplate = ({ data }) => {
  useEffect(() => {
    document.body.classList.add("overflow-auto");
    // will unmount
    return () => {
      document.body.classList.remove("overflow-auto");
    }
  }, [])

  const { title, metaDescription, body, slug,  heroImage } = data.contentfulPage

  const { siteUrl } = useSiteMetadata()
  const articleUrlEncoded = encodeURIComponent(siteUrl+"/"+slug)
  const articleTitleEncoded = encodeURIComponent(title)

  let ogImage
  try {
    ogImage = heroImage.ogimg.src
  } catch (error) {
    ogImage = null
  }

  return (

    <div className="page-container">
        <LBNavigation isHome={false}/>
        <Layout pageClass="page-content">
        <SEO
            title={title}
            description={
              metaDescription
                ? metaDescription.internal.content
                : body.childMarkdownRemark.excerpt
            }
          />
        {slug === "su-di-me" ?
          <>
            <HeroPageProfilo title={title} image={heroImage} height={'50vh'} pageType="page" />
            <Container maxWidth={'1250px'}>
              <div className="page-body text-styles su-di-me row no-gutters justify-content-center">
                <section className="section-paragraph col-md-6">
                  <h2>Cosa faccio</h2>
                  <p>Mi occupo di <strong>UX/UI design e sviluppo web</strong> nell’ambito <strong>e-commerce</strong> da più di 15 anni. Attualmente lavoro come <strong>Product Designer e Developer</strong> nel team digital di Gucci.</p>
                  <p>Le mia attività giornaliera è far sì, insieme ai miei colleghi, che gucci.com continui ad essere leader digitale nell'industria del lusso.</p>
                  <h2>La mia storia</h2>
                  <p>Il mio primo approccio nella luxury industry è iniziato nel 2010 quando ho accettato di <strong>gestire la parte web design</strong> per gli e-commerce delle aziende del Gruppo Kering (YSL, Balenciaga, Bottega Veneta, ecc.), lasciando così la mia agenzia che si occupava di advertising e comunicazione digitale.</p>
                  <p>In questi dieci anni di carriera in ambiente internazionale ho avuto la possibilità di sviluppare una <strong>forte esperienza nel lavoro in team</strong>, <strong>gestire progetti digitali</strong> dal design all'implementazione tecnica e <strong>coordinare risorse esterne</strong>.</p>
                </section>
                <section className="section-paragraph col-md-6">
                <h2>Skills</h2>
                <ul>
                    <li>Product strategy and ideation</li>
                    <li>Usability/Accessibility heuristic audits</li>
                    <li>User research and testing</li>
                    <li>Wireframing and iteration</li>
                    <li>Low and high fidelity prototyping</li>
                    <li>Interaction and motion design</li>
                    <li>Digital branding and style guides</li>
                    <li>Asset creation and optimisation</li>
                    <li>Front-end development</li>
                  </ul>                
                </section>
              </div>
              <div className="page-body text-styles su-di-me row no-gutters justify-content-center">
                <section className="section-paragraph col-md-12">
                  <h2>Passioni</h2>
                  <p>Sono appassionato di design incentrato sull'utente, architettura dell'informazione, innovazione e tendenze emergenti. Sempre interessato a nuove sfide e opportunità.</p>
                  <p>Quando non sono a lavorare sul Mac, mi trovi nel mio box di CrossFit preferito a Prato o il fine settimana sulle strade dell’Appennino Tosco Emiliano in sella alla mia moto.</p>
                  <p>👋🏼 Grazie per essere passato da qui.</p>
                </section>
              </div>
            </Container>
          </>
          : 
          <>
            <HeroPage title={title} image={heroImage} height={'50vh'} pageType="page" />
            <Container>
              <div className="page-body text-styles" dangerouslySetInnerHTML={{ __html: body.childMarkdownRemark.html }}></div>
            </Container>
          </>}
          <LBCondividi articleUrlEncoded={articleUrlEncoded} articleTitleEncoded={articleTitleEncoded} />
        </Layout>
    </div>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      heroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        ogimg: resize(width: 1800) {
          src
        }
      }
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
    }
  }
`

export default PageTemplate
